@use '../abstracts' as *;

/* ####################################################################################

  Sass導入メモ
  baseの中に入れたかったが読み込み順の都合上独立化

#################################################################################### */


/* Wysiwyg editor
**************************************** */
.mce-content-body {
  text-align: justify;
  text-justify: inter-ideograph;
}
.mce-content-body ol {
  list-style: decimal;
}
.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body ul:where(:not(.post--terms)) li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2em;
  padding-top: 0.5em;
}
.mce-content-body ul li {
  list-style: none;
}
.mce-content-body ul:where(:not(.post--terms)) li::before {
  content: "";
  position: absolute;
  top: 1.05em;
  left: -1.35em;
  width: 10px;
  height: 10px;
  border: 1px solid #80d4d4;
  background-color: #80d4d4;
}
.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: #fff;
}
.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}
.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}
.mce-content-body hr,
hr {
  border-top: 1px solid #dfdfdf;
  margin: 2.5em 0;
}
.mce-content-body .box,
.mce-content-body .list-2-column {
  padding: 24px 32px;
  background-color: #f4f0ec;
  border-radius: 16px;
}
.mce-content-body .box:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body .box:not(:last-child) {
  margin-bottom: 2em;
}
.mce-content-body .editor-ttl + .box,
.mce-content-body .editor-ttl2 + .box {
  margin-top: 0;
}
.mce-content-body .box-wh {
  background-color: #fff !important;
  border: 1px solid #ddd;
}
.mce-content-body .box.interview_box {
  min-height: 280px;
}
.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}
.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}
.mce-content-body .aligncenter {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}
.mce-content-body img.aligncenter {
  display: block;
}
.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}
:where(.single) .mce-content-body p,
:where(.single) .mce-content-body h6 {
  letter-spacing: 0.08em;
  line-height: 2.125;
}
.mce-content-body p:where(:not(:last-child)) {
  margin-bottom: 1em;
}
.mce-content-body strong {
  font-weight: bold;
}
.mce-content-body blockquote {
  margin: 1em 0;
  padding: 15px 40px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: #f8f8f8;
}
.mce-content-body blockquote p {
  margin-bottom: 0;
}
.mce-content-body blockquote::before,
.mce-content-body blockquote::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: 0.25;
}
.mce-content-body blockquote::before {
  background-image: url("../images/common/icon-quote_left.svg");
  top: 13px;
  left: 10px;
}
.mce-content-body blockquote::after {
  background-image: url("../images/common/icon-quote_right.svg");
  bottom: 13px;
  right: 10px;
}
.txt-link a,
.mce-content-body a {
  color: #0a7bbb;
  text-decoration: underline;
}
.mce-content-body a:hover {
  text-decoration: none;
}
.mce-content-body .footer_text small {
  display: block;
  margin-top: 8px;
  text-align: right;
}
.mce-content-body table {
  border: none;
  height: auto !important;
}
.mce-content-body table:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body table:not(:last-child) {
  margin-bottom: 1em;
}
.mce-content-body th,
.mce-content-body td {
  padding: 8px 16px;
  line-height: 1.5;
}
.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}
.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #ddd;
  vertical-align: middle;
}
.mce-content-body th {
  min-width: 120px;
  text-align: left;
}
.mce-content-body sup {
  font-size: 0.75rem; /* 12px */
  color: #555;
}
.bold {
  font-family: "Shuei KakuGo Gin B";
  color: #F73837;
}

.txt-xl {
  font-size: 1.666666rem;
  font-weight: bold;
}
.txt-lg {
  font-family: "Shuei KakuGo Gin B";
  font-size: 1.125rem;
  line-height: 2.2 !important;
  letter-spacing: 0.03em;
}
.txt-sm {
  font-size: 0.875rem;
  line-height: 1.7;
  letter-spacing: 0.03em;
}
.txt-xs {
  font-size: 0.8125rem;
  line-height: 1.6;
  letter-spacing: 0.02em;
}
.txt-xxs {
  font-size: 0.6875rem;
  letter-spacing: 0.06em;
  line-height: 1.6;
}
@media screen and (max-width: 767px) {
  .txt-lg {
    font-size: 1.071rem; /* 15px */
  }
  .txt-sm {
    font-size: 0.8675rem; /* 13px */
  }
  .txt-ctr-sp {
    text-align: center;
  }
}

/* iframe */
.mce-content-body .iframe {
  position: relative;
}
.mce-content-body .iframe:not(:last-child) {
  margin-bottom: 2em;
}
.mce-content-body .iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mce-content-body .iframe--youtube,
.mce-content-body .iframe--google_map {
  padding-top: calc(9 / 16 * 100%);
}

.mce-content-body pre {
  word-break: break-all;
  white-space: break-spaces;
}

/* table scroll */
.table-sp-scroll {
  white-space: nowrap;
}
.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}
.scroll table {
  margin: 0;
}
.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  color: #504a4a;
  font-size: 0.875rem; /* 14px */
}

.mce-content-body .wp-caption {
  width: 100% !important;
}

@media screen and (max-width: 767px) {
  .mce-content-body p:not([class]) {
    text-align: left !important;
  }
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 20px auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 15px;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body ul li::before {
    top: 1.15em;
    width: 8px;
    height: 8px;
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: 8px;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body .box {
    padding: 24px;
    border-radius: 16px;
  }
}

/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor {
  padding: 24px !important;
  max-height: 100vh !important;
  overflow-y: scroll !important;
}

@media screen and (max-width: 767px) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}

/* add quicktag
*************************************************** */
/* 見出し */
.mce-content-body h1:not(:last-child),
.mce-content-body h2:where(:not(:last-child)),
.mce-content-body h3:where(:not(.post--ttl):not(:first-child)),
.mce-content-body h4:not(.editor-ttl):not(:first-child),
.mce-content-body h5:not(.editor-ttl2):not(:first-child) {
  margin-top: 1.5em;
}
.mce-content-body .editor-ttl:not(:first-child),
.mce-content-body .editor-ttl2:not(:first-child),
.mce-content-body .editor-ttl3:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body h1:not(:last-child),
.mce-content-body h2:not(:last-child),
.mce-content-body h3:not(.post--ttl):not(:last-child),
.mce-content-body h4:not(.editor-ttl):not(:last-child),
.mce-content-body h5:not(.editor-ttl2):not(:last-child) {
  margin-bottom: 1.25em;
}
.mce-content-body .editor-ttl:where(:not(:last-child)),
.mce-content-body .editor-ttl2:not(:last-child),
.mce-content-body .editor-ttl3:not(:last-child) {
  margin-bottom: 1em;
}

/* ブログのhスタイル */
.mce-style-type2.mce-content-body :is(h2, h3, h4),
.wp-editor.mce-content-body :is(h2, h3, h4) {
  font-family: "Shuei KakuGo Gin B", sans-serif;
}
.mce-style-type2.mce-content-body h2,
.wp-editor.mce-content-body h2 {
  padding: 1.25rem 2rem;
  color: #f7f7f7;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.05em;
  border-radius: 4px;
  background-color: #000;
}
.mce-style-type2.mce-content-body h3,
.wp-editor.mce-content-body h3 {
  padding-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.7;
  letter-spacing: 0.05em;
  border-bottom: 2px solid #000;
}
.mce-style-type2.mce-content-body h4:where(:not(:first-child)),
.wp-editor.mce-content-body h4:where(:not(:first-child)) {
  margin-top: 2em !important;
}
.mce-style-type2.mce-content-body h4,
.wp-editor.mce-content-body h4 {
  display: flex;
  padding-block: 1rem;
  font-size: 1.25rem;
  line-height: 1.7;
  letter-spacing: 0.05em;
}
.mce-style-type2.mce-content-body h4:where(:not(:last-child)),
.wp-editor.mce-content-body h4:where(:not(:last-child)) {
  margin-bottom: 0 !important;
}
.mce-style-type2.mce-content-body h4::before,
.wp-editor.mce-content-body h4::before {
  content: '';
  margin-left: 0.75rem;
  margin-top: calc(0.7em / 2 + 2px);
  height: calc(1lh - 1rem - 2px);
  border-radius: 2px;
  aspect-ratio: 1;
  background-color: #000;
}
.mce-style-type2.mce-content-body :is(.box-point, .box-attention),
.wp-editor.mce-content-body :is(.box-point, .box-attention) {
  display: flex;
  align-items: flex-start;
  padding: 2.5rem 2rem;
  border-radius: 8px;
  border: 3px solid;
  background-color: #F9F9F9;
}
.mce-style-type2.mce-content-body :is(.box-point, .box-attention)::before,
.wp-editor.mce-content-body :is(.box-point, .box-attention)::before {
  flex-shrink: 0;
  width: 15.5rem;
  padding-left: 2rem;
  font-family: Outfit;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.0533333333em;
  background-position: left center;
  background-repeat: no-repeat;
}
.mce-style-type2.mce-content-body .box-point,
.wp-editor.mce-content-body .box-point {
  border-color: #437DD5;
}
.mce-style-type2.mce-content-body .box-point::before,
.wp-editor.mce-content-body .box-point::before {
  content: 'POINT';
  color: #437DD5;
  background-image: url(../images/common/box-point.svg);
}
.mce-style-type2.mce-content-body .box-attention,
.wp-editor.mce-content-body .box-attention {
  border-color: #F83838;
}
.mce-style-type2.mce-content-body .box-attention::before,
.wp-editor.mce-content-body .box-attention::before {
  content: 'ATTENTION';
  color: #F83838;
  background-image: url(../images/common/box-attention.svg);
}
@media screen and (max-width: 767px) {
  .mce-style-type2.mce-content-body h2,
  .wp-editor.mce-content-body h2 {
    padding: 0.8571428571rem 1.4285714286rem;
    font-size: 1.4285714286rem;
  }
  .mce-style-type2.mce-content-body h3,
  .wp-editor.mce-content-body h3 {
    font-size: 1.2857142857rem;
  }
  .mce-style-type2.mce-content-body h4,
  .wp-editor.mce-content-body h4 {
    font-size: 1.1428571429rem;
  }
  .mce-style-type2.mce-content-body :is(.box-point, .box-attention),
  .wp-editor.mce-content-body :is(.box-point, .box-attention) {
    flex-direction: column;
    gap: .7rem;
    padding: 1.2857142857rem 1.7142857143rem;
  }
  .mce-style-type2.mce-content-body :is(.box-point, .box-attention)::before,
  .wp-editor.mce-content-body :is(.box-point, .box-attention)::before {
    width: 100%;
  }
}


/* blog以外のhスタイル */
.wp-editor.mce-content-body:not(.mce-style-type2) h2:where(:not(:last-child)),
.mce-content-body:not(.mce-style-type2) h2 {
  position: relative;
  padding: 20px 24px;
  background: rgba(27, 39, 55, 0.05);
  line-height: 1.7;
  font-size: 1.5rem;
  color: #000;
  letter-spacing: 0.04em;
  font-family: "Outfit", "Shuei KakuGo Gin B", sans-serif;
  font-weight: 500;
}
.wp-editor.mce-content-body:not(.mce-style-type2) h3:where(:not(.post--ttl)),
.mce-content-body:not(.mce-style-type2) h3:where(:not(.post--ttl)) {
  position: relative;
  padding-left: 18px;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: 0.04em;
  font-family: "Outfit", "Shuei KakuGo Gin B", sans-serif;
  font-weight: 500;
}
.wp-editor.mce-content-body:not(.mce-style-type2) h3:not(.post--ttl)::before,
.mce-content-body:not(.mce-style-type2) h3:not(.post--ttl)::before,
.mce-content-body:not(.mce-style-type2) h4:not(.editor-ttl)::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.wp-editor.mce-content-body:not(.mce-style-type2) h3:not(.post--ttl)::before,
.mce-content-body:not(.mce-style-type2) h3:not(.post--ttl)::before {
  width: 4px;
  height: 90%;
  border-radius: 4px;
  background: #000;
}
.wp-editor.mce-content-body:not(.mce-style-type2) h4:not(.editor-ttl),
.mce-content-body:not(.mce-style-type2) h4:not(.editor-ttl) {
  position: relative;
  padding-left: 22px;
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: 0.04em;
  font-family: "Outfit", "Shuei KakuGo Gin B", sans-serif;
  font-weight: 500;
}
.wp-editor.mce-content-body:not(.mce-style-type2) h4:not(.editor-ttl)::before,
.mce-content-body:not(.mce-style-type2) h4:not(.editor-ttl)::before {
  width: 8px;
  height: 8px;
  background: #000;
  border-radius: 100px;
}
.editor-ttl {
  position: relative;
  font-size: 2rem; /* 32px */
  line-height: 1.5;
  letter-spacing: 0.04em;
  font-family: "Outfit", "Shuei KakuGo Gin B", sans-serif;
  font-weight: 500;
}
.editor-ttl2 {
  font-size: 1.5rem; /* 24px */
  line-height: 1.5;
  letter-spacing: 0.04em;
  font-family: "Outfit", "Shuei KakuGo Gin B", sans-serif;
  font-weight: 500;
}
.editor-ttl3 {
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: 0.04em;
  font-family: "Outfit", "Shuei KakuGo Gin B", sans-serif;
  font-weight: 500;
}
.editor-ttl-en,
.editor-ttl-en2 {
  position: relative;
  margin-bottom: 1rem !important;
  padding-bottom: 1rem;
  line-height: 1;
  font-size: clamp(rem(20), vw(40), rem(40));
  color: #000;
  font-weight: 600;
}
.editor-ttl-en::after,
.editor-ttl-en2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 2px;
  background-color: #80d4d4;
}
.editor-ttl-en2 {
  padding-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 400;
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: 0.875rem; /* 14px */
  color: #504a4a;
}
.txt-attention::before {
  content: "※";
}

/* テーブル */
.table1 th,
.table1 td,
.table2 th,
.table2 td {
  line-height: 1.45;
}
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
}
.table1 th {
  font-size: 1.125rem; /* 18px */
}
.table1 th::before,
.table1 td::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 99.25%;
}
.table1 th::before {
  left: 0;
  bottom: 0;
  height: 2px;
  background-color: #80d4d4;
}
.table1 td::before {
  right: 0;
  height: 1px;
  background-color: #dddddd;
}
/* IE */
_:-ms-input-placeholder,
:root .table1 th {
  border-bottom: 2px solid #80d4d4;
}
_:-ms-input-placeholder,
:root .table1 td {
  border-bottom: 1px solid #dddddd;
}
_:-ms-input-placeholder,
:root .table1 th::before,
_:-ms-input-placeholder,
:root .table1 td::before {
  display: none;
}

.table2 th,
.table2 td {
  border-left: none;
  border-right: none;
  padding: 24px 16px;
}
/* リスト2カラム */
.mce-content-body .list-2-column {
  border-radius: 16px;
  padding: 3%;
}
.mce-content-body .list-2-column ul {
  width: 50%;
  margin-block: 0;
}
.leapcar-bnr {
  transition: .4s all ease;
}
.leapcar-bnr:hover {
  opacity: .7;
}

@media screen and (max-width: 767px) {
  .mce-content-body:not(.mce-style-type2) h2 {
    padding: 16px;
    line-height: 1.5;
    font-size: 1.25rem;
  }
  .mce-content-body:not(.mce-style-type2) h3:not(.post--ttl) {
    font-size: 1.25rem;
  }
  .editor-ttl {
    font-size: 1.35rem; /* 20px */
  }
  .editor-ttl2 {
    font-size: 1.2rem; /* 18px */
  }
  .editor-ttl3 {
    font-size: 1rem; /* 16px */
    line-height: 1.5 !important;
  }

  /* テーブル */
  .table2.table-sp-block {
    border-top: 1px solid #ddd;
  }
  .table2.table-sp-block th {
    border: none;
    padding-bottom: 0;
    font-weight: bold;
    color: #504a4a;
    font-size: 0.888rem;
  }
  .table2.table-sp-block td {
    padding-top: 12px;
    border: none;
    border-bottom: 1px solid #ddd;
  }
  .table1.table-sp-block td::before {
    display: none;
  }

  /* 注意書き */
  .txt-attention {
    font-size: 0.929rem; /* 13px */
  }
  /* リスト2カラム */
  .mce-content-body .list-2-column {
    flex-direction: column;
    padding: 5%;
  }
  .mce-content-body .list-2-column ul {
    width: 100%;
  }
}

/* btn
********************************************** */
.btn + .btn {
  margin-top: 8px;
}
.flex .btn + .btn {
  margin-top: 0;
  margin-left: 16px;
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: 18px 50px;
  border-radius: 100px;
  width: 100%;
  max-width: 400px;
  color: #fff;
  background-color: #437dd5;
  font-family: "Outfit", "Shuei KakuGo Gin B", sans-serif;
  text-align: center;
  text-decoration: none;
}
.btn svg {
  fill: #fff;
}
.btn a:hover {
  background-color: #3360a4;
}
.btn-ctr {
  text-align: center;
}
.btn-ctr a {
  margin-inline: auto;
}

/* --- btn-gray --- */
.btn-gray a {
  background-color: #737373;
  color: #fff;
}
.btn-gray a:hover {
  background-color: #1e3443;
}

/* --- btn-next_page --- */
.btn.btn-next_page a {
  max-width: 100%;
  border-radius: 0;
  text-align: left;
  border-radius: 100px;
}

/* --- btn-en --- */
.btn-en a {
  display: inline-block;
  font-size: 1.125rem;
  font-family: "Outfit", "Shuei KakuGo Gin B", sans-serif;
}
.btn-en .arrow {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #437dd5;
  vertical-align: middle;
  transition: all 0.4s ease;
  margin-left: 16px;
}
.btn-en a:hover .arrow {
  background-color: #437dd5;
}
.btn-en .arrow i {
  display: inline-block;
  height: 16px;
  line-height: 1;
  overflow: hidden;
}
.btn-en .arrow svg {
  width: 16px;
  height: 16px;
  fill: #fff;
  transition: all 0.4s ease;
}
@keyframes arrow {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  20% {
    transform: translateX(16px);
    opacity: 1;
  }
  40% {
    transform: translateX(16px);
    opacity: 0;
  }
  60% {
    transform: translateX(-16px);
    opacity: 0;
  }
  80% {
    transform: translateX(-16px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.btn-en a:hover .arrow svg {
  animation: 0.4s linear arrow;
}

/* --- btn-wh --- */
.btn-wh a {
  color: #333;
  background-color: #fff;
}
.btn-wh svg {
  fill: #333;
}
.btn-wh a:hover {
  color: #fff;
}
.btn-wh a:hover svg {
  fill: #fff;
}

/* --- btn-cta --- */
.btn-cta a,
.btn a[href*='/contact/'] {
  background-color: #f83838 !important;
}
.btn-cta a:hover {
  background-color: #c4000b !important;
}

/* --- btn-e-book --- */
.btn-e-book a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d1e28;
}
.btn-e-book-wh a {
  color: #333;
  border: 1px solid #0d1e28;
  background-color: #FFF;
}
.btn-e-book-wh a svg {
  fill: #333;
}
.btn-e-book-wh a:hover {
  opacity: .8;
  color: #333;
  background-color: #FFF;
}
.btn-e-book a:hover {
  background-color: #444;
}

/* --- btn-blue --- */
.btn-blue a {
  background-color: #88cccc;
  color: #fff;
}
.btn-blue a:hover {
  background-color: #5eb9b9;
}

/* --- lps --- */
.lps_parts .btn a {
  background-color: #437dd5;
}
.lps_parts .btn a:hover {
  background-color: #3360a4;
}
.mce-content-body .btn.txt-ctr a {
  margin: 0 auto !important;
}
.mce-content-body .btn-editor-cta {
  margin-top: 1.5rem;
  margin-bottom: 4rem !important;
}
.mce-content-body .btn-editor-cta a {
  padding: 24px 40px;
  max-width: 720px;
}
.mce-content-body .btn-editor-cta a::before {
  content: "";
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  width: 14px;
  height: 13px;
  margin: auto 0;
  background-color: #fff;
  -webkit-clip-path: url(#cpath-arrow);
  clip-path: url(#cpath-arrow);
}

@media screen and (max-width: 767px) {
  .btn a {
    max-width: 100%;
    padding: 14px 40px;
  }
  .btn-en .arrow {
    width: 40px;
    height: 40px;
  }
  .flex .btn + .btn {
    margin-top: 14px;
    margin-left: 0;
  }
  .btn.btn-next_page a {
    text-align: center;
  }
  .mce-content-body .btn-editor-cta a {
    padding: 20px 40px;
    line-height: 1.65;
  }
  .mce-content-body .btn-editor-cta a::before {
    right: 16px;
  }
}

/* editor_cta_box
********************************************** */
.editor_cta_box {
  padding: 30px 90px;
  border-radius: 16px;
  background-color: #f4f0ec;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
  background-image: url(../images/common/editor_cta_box_bg.jpg);
}
.editor_cta_box--ttl {
  font-size: 4.5rem;
  font-weight: 400;
}
.editor_cta_box--tel a {
  margin-right: 16px;
  font-size: 2.28rem;
  text-decoration: none;
  color: #333;
}
.editor_cta_box--tel a i {
  font-size: 1.735rem;
  transform: translateY(-2px);
  margin-right: 8px;
}
.editor_cta_box--btns .btn a {
  min-width: 240px;
  border-radius: 8px;
  padding: 14px 32px;
  font-size: 0.888rem;
  text-decoration: none;
}
.editor_cta_box--btns .btn:nth-child(1) a {
  background-color: #e02828;
}
.editor_cta_box--btns .btn:nth-child(1) a:hover {
  background-color: #c4000b;
}
.editor_cta_box--btns .btn:nth-child(2) a {
  background-color: #0d1e28;
}
.editor_cta_box--btns .btn:nth-child(2) a:hover {
  background-color: #444;
}
.editor_cta_box--btns .btn.btn-gray a {
  background-color: #737373;
}
.editor_cta_box--btns .btn.btn-gray a:hover {
  background-color: #1e3443;
}
.editor_cta_box--btns .btn a .free {
  font-size: 0.8rem;
}
.editor_cta_box--btns .btn a img {
  margin: 0 0 5px 5px;
}
.editor_cta_box--btns .btn .icon-arrow {
  right: 14px;
}
.editor_cta_box--btns .btn .icon-arrow i {
  height: 14px;
}
.editor_cta_box--btns .btn .icon-arrow svg {
  width: 14px;
  height: 14px;
}

@media screen and (max-width: 767px) {
  .editor_cta_box {
    padding: 32px;
    background-size: cover;
    background-position: center;
  }
  .editor_cta_box--ttl {
    font-size: 2.5rem;
  }
  .editor_cta_box--btns {
    flex-direction: column;
  }
  .editor_cta_box--btns.flex .btn + .btn {
    margin-top: 0;
  }

  .editor_cta_box--tel {
    text-align: center;
  }
  .editor_cta_box--tel a {
    display: block;
    margin-right: 0;
    font-size: 8.5vw;
    text-align: center;
  }
  .editor_cta_box--tel a i {
    font-size: 7vw;
  }
}

/* right_flex
********************************************** */
/* --- right_flex --- */
.editor_right_flex {
  display: flex;
  gap: 3%;
  margin-inline: auto;
}
.editor_right_flex .left_area {
  flex: 1;
  text-align: center;
}
.editor_right_flex .left_area .box {
  display: grid;
  align-content: center;
  height: 100%;
}
.editor_right_flex .right_area {
  display: grid;
  place-content: center;
  width: 30%;
  min-width: 170px;
}
.editor_right_flex .right_area img {
  margin: 0;
  width: 100%;
  height: auto;
}
.editor_right_flex .right_area:empty,
.editor_right_flex .right_area:not(:has(img)) {
  display: none;
}
@media screen and (max-width: 767px) {
  .editor_right_flex {
    flex-direction: column-reverse;
    gap: 24px;
  }
  .editor_right_flex .left_area,
  .editor_right_flex .right_area {
    width: 100%;
  }
}
.img_border {
  border: 1px solid #c5c5c5;
}

/* プラグイン「Pz-LinkCard」の元スタイル
*************************************************** */
.linkcard {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
}
.linkcard:hover {
  /*OPACITY*/
}
.linkcard img {
  margin: 0 !important;
  padding: 0;
  border: none;
}
.lkc-link {
  text-decoration: none !important;
}
.lkc-external-wrap,
.lkc-internal-wrap,
.lkc-this-wrap {
  /*HEIGHT*/
  max-width: 100%;
}
.lkc-internal-wrap {
  position: relative;
  margin: 0;
  border: 1px solid #dddddd;
  padding: 0;

  /*SHADOW*/
  background-color: #fff;
  /*IN-IMAGE*/
  /*WRAP*/
}
.lkc-internal-wrap::after {
  /*WRAP-AFTER*/
}
.lkc-internal-wrap::before {
  /*WRAP-BEFORE*/
}
.lkc-external-wrap {
  position: relative;
  margin: 0;
  border: 1px solid #0d1e28;
  padding: 0;

  /*SHADOW*/
  /*HEIGHT*/
  /*max-width: 840px;*/
  background-color: #ffffff;
  /*EX-IMAGE*/
  /*WRAP*/
}
.lkc-external-wrap::after {
  /*WRAP-AFTER*/
}
.lkc-external-wrap::before {
  /*WRAP-BEFORE*/
}
.lkc-this-wrap {
  position: relative;
  margin: 0;
  border: 1px solid #dedede;
  padding: 0;

  /*SHADOW*/
  /*HEIGHT*/
  max-width: 840px;
  background-color: #eeeeee;
  /*TH-IMAGE*/
  /*WRAP*/
}
.lkc-this-wrap::after {
  /*WRAP-AFTER*/
}
.lkc-this-wrap::before {
  /*WRAP-BEFORE*/
}
.lkc-card {
  position: relative;
  padding: 20px 40px 24px;
  margin: 0;
}
.linkcard--txt {
  position: absolute;
  top: 0;
  left: 2rem;
  display: inline-block;
  background-color: #0d1e28;
  border-radius: 40px;
  padding: 6px 24px;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1;
  transform: translateY(-60%);
}
.lkc-content {
  /*CONTENT-PADDING*/
  /*CONTENT-INSET*/
  /*CONTENT-BGCOLOR*/
  /*CONTENT-HEIGHT*/
  margin-top: 8px;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  height: auto;
  /*SEPARATOR*/
}
.lkc-title-text {
  color: #1d2737;
  /*OUTCOLOR-TITLE*/
  font-size: 1rem;
  line-height: 24px;
  font-weight: bold;
  overflow: hidden;
  word-break: break-all;
}
.lkc-title-text:hover {
  text-decoration: none;
}
.lkc-url {
  color: #225a94;
  /*OUTCOLOR-URL*/
  font-size: 62.5%;
  line-height: 10px;
  margin-top: 0.25em;
  margin-bottom: 1em;
  font-weight: normal;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.lkc-url-info {
  color: #225a94;
  /*OUTCOLOR-URL*/
  font-size: 62.5%;
  line-height: 10px;
  font-weight: normal;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
}
.lkc-added {
  color: #1d2737;
  /*OUTCOLOR-ADDED*/
  font-size: 75%;
  line-height: 12px;
  /*HEADING*/
}
.lkc-excerpt {
  color: #333333;
  /*OUTCOLOR-EXCERPT*/
  font-size: 68.75%;
  line-height: 17px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  /*NONE-EXCERPT*/
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.lkc-more-text {
  color: #444444;
  /*OUTCOLOR-MORE*/
  font-size: 75%;
  line-height: 40px;
  /*STYLE-MORE*/
}
.lkc-thumbnail {
  max-width: 100px;
  max-height: 108px;
  padding: 0;
  margin: 0 20px 0 0;
  float: left;
}
.lkc-thumbnail .post_img::after {
  display: none;
}
.lkc-thumbnail-img {
  width: 100px;
  max-height: 108px;
  border: 1px solid #222;

  /*THUMBNAIL-SHADOW*/
}
.lkc-info {
  padding: 0;
  color: #1d2737;
  /*OUTCOLOR-INFO*/
  font-size: 75%;
  line-height: 12px;
  white-space: nowrap;
  overflow: hidden;
  /*NONE-INFO*/
}
.lkc-info .post_img,
.lkc-info .lkc-domain {
  display: none !important;
}
.lkc-date {
  display: block !important;
  float: none;
  margin-bottom: 0.5em;
  font-size: 68.75%;
}
.lkc-infotext {
  /*INFO-TEXT*/
}
.lkc-domain {
  color: #1d2737;
  /*OUTCOLOR-INFO*/
  font-size: 75%;
  line-height: 12px;
  text-decoration: none;
  display: inline !important;
}
.lkc-domain:hover {
  color: #1d2737;
  /*OUTCOLOR-INFO*/
  font-size: 75%;
  line-height: 12px;
  text-decoration: underline;
  display: inline !important;
}
.lkc-favicon {
  height: 16px;
  width: 16px;
  margin: 0 4px !important;
  border: none;
  vertical-align: text-bottom;
  display: inline !important;
}
.lkc-name a {
  margin: 0;
  padding: 0;
  float: right;
  opacity: 0.5;
  text-decoration: none;
  display: none;
}
.lkc-share {
  text-shadow: none;
}
.lkc-sns-tw {
  margin: 0;
  padding: 0 1px;
  color: #5ea9dd !important;
  background-color: #f5f8fa !important;
  font-size: 56.25%;
  text-decoration: underline !important;
  font-weight: bold !important;
  white-space: nowrap;
}
.lkc-sns-fb {
  margin: 0;
  padding: 0 1px;
  color: #ffffff !important;
  background-color: #3864a3 !important;
  font-size: 56.25%;
  text-decoration: underline !important;
  font-weight: bold !important;
  white-space: nowrap;
}
.lkc-sns-hb {
  margin: 0;
  padding: 0 1px;
  color: #ff6464 !important;
  background-color: #ffefef !important;
  font-size: 56.25%;
  text-decoration: underline !important;
  font-weight: bold !important;
  white-space: nowrap;
}
.lkc-sns-gp {
  margin: 0;
  padding: 0 1px;
  color: #dd4e42 !important;
  background-color: #ffffff !important;
  font-size: 56.25%;
  text-decoration: underline !important;
  font-weight: bold !important;
  white-space: nowrap;
}
.lkc-sns-po {
  margin: 0;
  padding: 0 1px;
  color: #eeeeee !important;
  background-color: #ee4055 !important;
  font-size: 56.25%;
  text-decoration: underline !important;
  font-weight: bold !important;
  white-space: nowrap;
}
.clear {
  clear: both;
}
blockquote.lkc-quote {
  background-color: transparent;
  background-image: none;
  padding: 0;
  margin: 0;
  border: none;
}
.lkc-iframe-wrap {
  margin: 0;
  max-width: 840px;
}
.lkc-iframe {
  padding: 0;
  margin: 0;
  width: 100%;
}
/*OPTION*/
@media screen and (max-width: 600px) {
  .linkcard {
    margin: 0!important;
  }
  .lkc-content {
    position: relative;
    padding-bottom: 24px;
  }
  .lkc-content::before {
    content: "この記事を読む";
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 20px;
    font-size: 13px;
    line-height: 24px;
  }
  .lkc-content::after {
    content: "";
    position: absolute;
    bottom: 6px;
    right: 0;
    width: 12px;
    height: 12px;
    background-image: url("../images/common/arrow.svg");
    background-repeat: no-repeat;
  }
  .lkc-title {
    font-size: 100%;
    line-height: 21px;
  }
  .lkc-excerpt {
    display: none;
  }
  .lkc-thumbnail {
    max-width: 90px;
  }
  .lkc-thumbnail-img {
    max-width: 90px;
  }
  .lkc-info {
    font-size: 100%;
  }
  .lkc-sns-po {
    font-size: 80%;
  }
  .linkcard--txt {
    left: 0.5rem;
  }
  .lkc-card {
    padding: 20px 16px 16px;
  }
  .lkc-date {
    margin-bottom: 0.75em;
    font-size: 75%;
  }
  .lkc-title-text {
    font-size: 90%;
    line-height: 1.3;
    margin-bottom: .365em;
  }
  .lkc-url {
    display: none;
    margin-top: 1.5em;
    margin-bottom: 0;
    text-align: right;
    font-size: 70%;
  }
}
@media screen and (max-width: 480px) {
  .lkc-title {
    font-size: 100%;
    line-height: 19px;
  }
  .lkc-excerpt {
    font-size: 50%;
  }
  .lkc-thumbnail-img {
    max-width: 70px;
  }
}
@media screen and (max-width: 320px) {
  .lkc-title {
    font-size: 68.75%;
    line-height: 16px;
  }
  .lkc-excerpt {
    font-size: 37.5%;
  }
  .lkc-thumbnail {
    max-width: 50px;
  }
  .lkc-thumbnail-img {
    max-width: 50px;
  }
}

/* wpcf7
*************************************************** */
.wpcf7 th,
.wpcf7 td {
  padding: 16px;
  vertical-align: middle;
  border: none;
  border-bottom: solid 1px #eee;
}
.wpcf7 table input[type="text"],
.wpcf7 table input[type="url"],
.wpcf7 table input[type="email"],
.wpcf7 table input[type="tel"],
.wpcf7 table input[type="date"],
.wpcf7 table input[type="password"],
.wpcf7 table textarea {
  width: 100%;
  border-radius: 3px;
  vertical-align: bottom;
}
.wpcf7 table input[type="text"],
.wpcf7 table input[type="url"],
.wpcf7 table input[type="email"],
.wpcf7 table input[type="tel"],
.wpcf7 table input[type="date"],
.wpcf7 table input[type="password"],
.wpcf7 select,
.wpcf7 textarea {
  margin: 0;
  padding: 5px 15px;
  border: 1px solid #ccc;
  font: inherit;
  font-size: 1rem;
}
.wpcf7 textarea {
  height: 100px;
}
.wpcf7 select {
  height: 40px;
}
.wpcf7 .wpcf7-list-item {
  display: block;
  margin: 0 0 0 0;
}
.wpcf7 .meeting-date span:not(:last-child) {
  margin-right: 8px;
}
.wpcf7 .help_text {
  display: block;
  margin-top: 16px;
}
.wpcf7 #formy_btn input,
.wpcf7 #formy_btn input[disabled] {
  color: var(--clr-wht);
  font-size: 123%;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic";
  line-height: 1;
  @include mq(sp) {
    display: block;
    font-size: sprem(16);
  }
}
/* .wpcf7 #formy_btn input:not([disabled]) {
  background-color: #f83838;
} */
.wpcf7 #formy_btn input:not([disabled]):hover {
  background-color: #55b2eb;
}

@media screen and (max-width: 767px) {
  .wpcf7 th,
  .wpcf7 td {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
  .wpcf7 th {
    padding-bottom: 0;
    border-bottom: none;
  }
}

/* VIDEO埋め込み
*************************************************** */
.wp-video {
  width: 100% !important;
  height: auto !important;
}
.mejs-container,
.mejs-container .mejs-controls,
.mejs-embed,
.mejs-embed body {
  width: 100% !important;
  height: auto !important;
}
.mejs-mediaelement {
  position: static !important;
}
.wp-video-shortcode video,
video.wp-video-shortcode {
  width: 100% !important;
  height: auto !important;
}

.page_parts_works .post--img img {
  height: 100%;
}

/* code装飾 */
.hljs-wrap {
  position: relative; /* 絶対配置の基準 */
}
.hljs-copy-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(201, 213, 245, 0.1);
  border: none;
  padding: 3px 10px;
  color: #999;
  cursor: pointer;
  transition: color .3s;
}
.hljs-copy-btn:hover {
  color: #eee
}

/* --- advice_blog_bnr --- */
.advice_blog_bnr {
  margin-bottom: rem(40);
  a {
    display: block;
    &:hover {
      opacity: 0.8;
    }
  }
  img {
    margin: 0;
  }
}
.blog_slides + .mce-content-body #toc_container + .advice_blog_bnr {
  @include mq(sp, min, ps) {
    margin-top: rem(40);
  }
}